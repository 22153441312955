
import Vue from 'vue'
import gsap from 'gsap';
import playWhenInView from '@/modules/@common/animations/play-when-in-view';

const mainTimeline = gsap.timeline();

export default Vue.extend({
  name: 'TopRightUi',
  mounted() {
    mainTimeline.add(this.animateUi(),0);
    playWhenInView(mainTimeline,`#${this.uniqueId}`)
  },
  props: {
    uniqueId:{
      type: String,
      default: 'top-right-ui',
    }
  },
  beforeDestroy() {
    mainTimeline.kill();
  },
  methods: {
    animateUi() {
      return gsap.timeline({ repeat: -1 })
      .add(gsap.timeline({ repeat: -1 })
        .delay(6)
        .to(`#${this.uniqueId} #cursor`, { x: 0, y: 0, duration: 1, ease: "power1.out" })
        .to(`#${this.uniqueId} #menu-bg`, { fill: "#F5FAFF", duration: 0.2, delay: 0.8, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #menu-bg`, { fill: "#D7EAFE", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #menu-dots`, { fill: "#066FEF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #menu-bg`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #modal`, { opacity: 1, duration: 0.4, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: 0, y: -192, duration: 1.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #option5`, { fill: "#F5FAFF", duration: 0.2, delay: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option5`, { fill: "#FFFFFF", duration: 0.2, delay: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option4`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option4`, { fill: "#FFFFFF", duration: 0.2, delay: 0.1, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option3`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option3`, { fill: "#FFFFFF", duration: 0.2, delay: 0.1, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option2`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option2`, { fill: "#FFFFFF", duration: 0.2, delay: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option1`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: 0, y: -80, duration: 1, delay:0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #option1`, { fill: "#FFFFFF", duration: 0.2, delay: 0.4, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option2`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option2`, { fill: "#FFFFFF", duration: 0.2, delay: 0.12, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option3`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option3`, { fill: "#FFFFFF", duration: 0.2, delay: 0.12, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #option4`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #option4`, { fill: "#F1FBEF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #tm-icon`, { fill: "#358821", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #tm-text`, { fill: "#358821", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #modal`, { opacity: 0, duration: 0.4, delay: 0.4 ,ease: "power1.inOut" })
        .to(`#${this.uniqueId} #progress`, { width: 138, duration: 0.4, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #menu-bg`, { fill: "#FFFFFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #menu-dots`, { fill: "#64707B", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: 240, y: -220, duration: 1.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { x: 248, duration: 9, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #progress`, { opacity: 0, duration: 0.4, delay: 3, ease: "power1.inOut" }, "<")
        .duration(18)
      , 0);
    }
  }
})
